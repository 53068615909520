import {
  filter,
  findLast,
  get,
  groupBy,
  isEmpty,
  isNil,
  max as getMax,
  min as getMin,
  orderBy,
  reduce,
  some,
  sum,
  sumBy
} from 'lodash';
import {
  CHECKLIST_MANAGER_STATES,
  RATING_CALCULATION,
  RATING_METHOD,
  RATING_MODE
} from './constants';
import { beatifyFloat } from './convertFloat';

// calculating ratings for some ui previews

const calculateRatingsAverage = (ratingsSum, questions) =>
  beatifyFloat(ratingsSum / questions.length) || 0;

const calculateRatingValuesSum = ratingValues =>
  beatifyFloat(sum(ratingValues.map(values => getMax(values))));

// calculating filled questions

const calculateQuestionsSum = questions => sumBy(questions, 'value');

const calculateQuestionsAverage = questions => calculateQuestionsSum(questions) / questions.length;

const calculateQuestionsSumWithPercentage = questionsWithValueAndPercentage => {
  return sum(
    questionsWithValueAndPercentage.map(({ value, percentage }) => value || 0 * (percentage || 1))
  );
};

const calculateQuestionsAverageWithPercentage = questionsWithValueAndPercentage => {
  const result = questionsWithValueAndPercentage
    .map(({ value, percentage }) => {
      const sum = (value || 0) * (percentage || 1);
      return [sum, percentage];
    })
    .reduce((values, percentages) => [values[0] + percentages[0], values[1] + percentages[1]], [
      0,
      0
    ]);

  return result[0] / result[1];
};

const withMode = ({
  result,
  ratingMode,
  max,
  min,
  trueResult,
  onlyPositiveScores = false,
  autoFail = false
}) => {
  if (autoFail) result = getMin([result, min]);

  let value = ratingMode === RATING_MODE.PERCENTAGE ? ((result - min) / (max - min)) * 100 : result;

  if (trueResult) return value;

  if ((onlyPositiveScores || ratingMode === RATING_MODE.PERCENTAGE) && value < 0) value = 0;
  const beautifulResult = beatifyFloat(value, 1);

  return ratingMode === RATING_MODE.PERCENTAGE && beautifulResult !== 'N/A'
    ? `${beautifulResult}%`
    : beautifulResult;
};

const calculateQuestions = ({
  ratingCalculation = RATING_CALCULATION.SUM,
  ratingMethod = RATING_METHOD.SIMPLE,
  ratingMode = RATING_MODE.NUMBERS,
  questions,
  max,
  min,
  trueResult = false,
  onlyPositiveScores = false,
  autoFail = false
}) => {
  if (ratingMethod === RATING_METHOD.WEIGHTED) {
    return withMode({
      result:
        ratingCalculation === RATING_CALCULATION.SUM
          ? calculateQuestionsSumWithPercentage(questions)
          : calculateQuestionsAverageWithPercentage(questions),
      ratingMode,
      max,
      min,
      trueResult,
      onlyPositiveScores,
      autoFail
    });
  }

  return withMode({
    result:
      ratingCalculation === RATING_CALCULATION.SUM
        ? calculateQuestionsSum(questions)
        : calculateQuestionsAverage(questions),
    ratingMode,
    max,
    min,
    trueResult,
    onlyPositiveScores,
    autoFail
  });
};

const transformQuestionsWithNoAnswerAsMax = (questions, noAnswerAsMax) =>
  questions.reduce((result, question) => {
    // * если стоит флаг учитывать значения NA как max и нажат NA
    if (isNil(question.value) && noAnswerAsMax) {
      return [
        ...result,
        {
          ...question,
          value: getMax(question.ratingValues),
          percentage: get(question, 'binding.percentage', 1)
        }
      ];
    }

    // * если  не стоит флаг учитывать значения NA как max и нажат NA
    if (isNil(question.value)) {
      return result;
    }

    // * стандартная обработка оценки по критерию
    return [...result, { ...question, percentage: get(question, 'binding.percentage', 1) }];
  }, []);

export const getScore = ({
  checklist,
  checklistManagerState,
  checklistDefinition,
  questionsWithValuesAndBindings_
}) => {
  if (isEmpty(checklist)) return '';
  let questionsWithValuesAndBindingsUnique = {};
  if (!!questionsWithValuesAndBindings_) {
    questionsWithValuesAndBindingsUnique = questionsWithValuesAndBindings_.filter((obj) => {
      if (!questionsWithValuesAndBindingsUnique[obj.id]) {
        questionsWithValuesAndBindingsUnique[obj.id] = true;
        return true;
      }
      return false;
    });
  } else {
    questionsWithValuesAndBindingsUnique = null;
  }

  if (isEmpty(questionsWithValuesAndBindingsUnique)) return 'N/A';

  const { ratingCalculation, ratingMethod, ratingMode, noAnswerAsMax } = checklistDefinition;

  if (checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED && !isEmpty(checklist))
    return ratingMode === RATING_MODE.PERCENTAGE && !isNil(checklist.score)
      ? `${beatifyFloat(checklist.score)}%`
      : beatifyFloat(checklist.score);

  const transformQuestions = transformQuestionsWithNoAnswerAsMax(
    questionsWithValuesAndBindingsUnique,
    noAnswerAsMax
  );

  const filteredQuestions = transformQuestions.map(question => {
    if (checklistDefinition.ratingMode !== RATING_MODE.PERCENTAGE) {
      return question;
    }
    if (question.ratingValues.length > 1) {
      return question;
    }
    return {
      ...question,
      value: Math.abs(question.value)
    };
  });

  const questions = checklistDefinition.noAnswerAsMax
    ? filteredQuestions
    : filteredQuestions.filter(question => question.value !== null);

  const onlyPositiveScores = get(checklistDefinition, 'onlyPositiveScores', false);

  const hasAutofail = some(
    questions,
    ({ value, binding }) =>
      (get(binding, 'checklistAutofailValues', []).includes(value) &&
        binding?.checklistAutofailEnabled) ||
      (get(binding, 'groupAutofailValues', []).includes(value) && binding?.groupAutofailEnabled)
  );

  // * calc max

  const max = calculateQuestions({
    trueResult: true,
    ratingCalculation,
    ratingMethod,
    questions: questions.map(({ ratingValues, ...question }) => {
      if (checklistDefinition.ratingMode !== RATING_MODE.PERCENTAGE) {
        return {
          ...question,
          value: getMax(ratingValues)
        };
      }
      if (
        Math.max(...ratingValues) === Math.min(...ratingValues) &&
        Math.max(...ratingValues) < 0
      ) {
        return {
          ...question,
          value: Math.abs(Math.max(...ratingValues))
        };
      }
      return {
        ...question,
        value: Math.max(...ratingValues)
      };
    })
  });

  // * calc min
  const min = some(
    questionsWithValuesAndBindingsUnique,
    ({ binding }) => binding?.questionGroup?.percentageCalculationStartingWithZero
  )
    ? 0
    : calculateQuestions({
        trueResult: true,
        max,
        ratingMethod,
        ratingCalculation,
        questions: questions.map(({ ratingValues, ...question }) => {
          if (checklistDefinition.ratingMode !== RATING_MODE.PERCENTAGE) {
            return {
              ...question,
              value: getMin(ratingValues)
            };
          }
          if (Math.max(...ratingValues) === Math.min(...ratingValues)) {
            return {
              ...question,
              value: 0
            };
          }
          return {
            ...question,
            value: Math.min(...ratingValues)
          };
        })
      });

  // * autofail check

  if (hasAutofail) {
    if (ratingMode === RATING_MODE.PERCENTAGE) {
      //  * calc new method

      const lastChecklistAutofailedQuestion = findLast(
        questions,
        ({ value, binding }) =>
          get(binding, 'checklistAutofailValues', []).includes(value) &&
          binding?.checklistAutofailEnabled
      );

      if (lastChecklistAutofailedQuestion) {
        return !isNil(lastChecklistAutofailedQuestion?.binding?.checklistAutofailResultPercentage)
          ? `${lastChecklistAutofailedQuestion?.binding?.checklistAutofailResultPercentage}%`
          : calculateQuestions({
              min,
              max,
              ratingMode,
              ratingMethod,
              ratingCalculation,
              questions: questions.map(({ ratingValues, ...question }) => ({
                ...question,
                value: getMin(ratingValues)
              }))
            });
      }

      const questionsByGroupId = reduce(
        groupBy(questionsWithValuesAndBindingsUnique, 'binding.questionGroupId'),
        (acc, questions, key) => ({ ...acc, [key]: orderBy(questions, 'position') }),
        {}
      );

      const autofailedGroupQuestionsByGroupId = groupBy(
        filter(
          questionsWithValuesAndBindingsUnique,
          ({ value, binding }) =>
            get(binding, 'groupAutofailValues', []).includes(value) && binding?.groupAutofailEnabled
        ),
        'binding.questionGroupId'
      );

      // * берем вопросы по группам и автофейленные вопросы в группах
      const resultQuestionsWithValues = reduce(
        questionsByGroupId,
        (acc, questions, groupId) => {
          // * проходимся по всем вопросам и ищем последний автофейл в группе
          if (autofailedGroupQuestionsByGroupId[groupId]) {
            const lastGroupAutofailedQuestion = findLast(
              orderBy(autofailedGroupQuestionsByGroupId[groupId], 'position')
            );

            // * берём процент последнего автофейла
            const { groupAutofailResultPercentage } = lastGroupAutofailedQuestion?.binding;

            // * подгоняем все ответы в вопросах этой группы
            const groupQuestions = questionsByGroupId[groupId].map(question => {
              let value;

              if (!question.binding.questionGroup?.percentageCalculationStartingWithZero) {
                // * если не стоит галочка о подсчёте с нуля в обе стороны - считаем "по классике"
                value =
                  (question.max - question.min) * (groupAutofailResultPercentage / 100) +
                  question.min;
              } else {
                // * если подсчёт в обе стороны, то смотрим на направление процента группы
                // * для отрицательного процента - исходим от минимума, для положительного - от максимума
                if (groupAutofailResultPercentage < 0) {
                  value =
                    (question.min < 0 ? question.min : 0) *
                    (Math.abs(groupAutofailResultPercentage) / 100);
                } else {
                  value =
                    (question.max > 0 ? question.max : 0) *
                    (Math.abs(groupAutofailResultPercentage) / 100);
                }
              }
              return { ...question, value };
            });

            //  * докидываем вопросы к остальным
            return [...acc, ...groupQuestions];
          }

          // * прокидываем обычные вопросы ( можно потом тут применить стандартные правила фильтрации )

          return [...acc, ...questions];
        },
        []
      );

      // * применяем к "новым"  вопросам с ответами те же правила что и всегда
      const newQuestions = transformQuestionsWithNoAnswerAsMax(
        resultQuestionsWithValues,
        noAnswerAsMax
      );

      // * считаем другие макс и мин для вопросов
      const newMax = calculateQuestions({
        trueResult: true,
        ratingCalculation,
        ratingMethod,
        questions: newQuestions.map(({ ratingValues, ...question }) => ({
          ...question,
          value: getMax(ratingValues)
        }))
      });

      // * calc min
      const newMin = some(
        questionsWithValuesAndBindingsUnique,
        ({ binding }) => binding?.questionGroup?.percentageCalculationStartingWithZero
      )
        ? 0
        : calculateQuestions({
            trueResult: true,
            max,
            ratingMethod,
            ratingCalculation,
            questions: newQuestions.map(({ ratingValues, ...question }) => ({
              ...question,
              value: getMin(ratingValues)
            }))
          });

      return calculateQuestions({
        min: newMin,
        max: newMax,
        ratingMode,
        ratingMethod,
        ratingCalculation,
        questions: newQuestions
      });
    }

    const sumMin = sumBy(questionsWithValuesAndBindingsUnique, 'min');
    const minimalRateValue = getMin([
      min,
      hasAutofail && ratingMethod !== RATING_METHOD.WEIGHTED && sumMin
    ]);

    // * autofailed checklist score
    return calculateQuestions({
      min: minimalRateValue,
      max,
      ratingMode,
      ratingMethod,
      ratingCalculation,
      questions: questions.map(({ ratingValues, ...question }) => ({
        ...question,
        value: getMin(ratingValues)
      })),
      onlyPositiveScores,
      autoFail: true
    });
  }

  return calculateQuestions({
    min,
    max,
    ratingMode,
    ratingMethod,
    ratingCalculation,
    questions,
    onlyPositiveScores
  });
};

export { calculateRatingsAverage, calculateRatingValuesSum, calculateQuestions };
