import { Button, Checkbox, message, Tooltip, Typography } from 'antd';
import ClientInteractionLink from 'components/ClientInteractionLink';
import CommentsPreview from 'components/Comments/CommentsPreview';
import CustomFieldValueView from 'components/CustomFieldValueView';
import StatusSelector from 'components/PhoneCallPlayer/RecordPlayer/StatusSelector';
import { TagsDisplay } from 'components/Tags/TagsDisplay';
import UserPreview from 'components/UserPreview';
import addScopeToObjectKeys from 'core/utils/addScopeToObject';
import { CALL_DIRECTION_LITERALS } from 'core/utils/constants';
import { beatifyFloat } from 'core/utils/convertFloat';
import { reduce, compact, get, isArray, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { utils } from 'react-media-player';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FolderOpenOutlined, LinkOutlined, LoadingOutlined } from '@ant-design/icons';
import { setCheckbox } from 'redux/ui/clientInteractionPage/reducer';
import SText from 'components/Standard/SText';
import { getChainNumber } from 'core/utils/getChainNumber';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DeactivatedUserLinkToCommunication } from './DeactivatedLinkToCommunication';

const { Text } = Typography;

export const StyledTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    background-color: white !important;
  }
`;

export const renderCustomField = ({ key, customFieldsByKeys }) => (
  data,
  record,
  wrapValue = true
) => {
  return CustomFieldValueView({
    customFields: addScopeToObjectKeys('customField', record.customFields),
    customFieldsByKeys,
    customFieldKey: key,
    value: data,
    wrapValue
  });
};

// array for m-ly select
let startId = null;
let endId = null;

const ColumnsRender = ({ onRowExpand, customFieldsByKeys, col, text, record, selection, ids }) => {
  const dispatch = useDispatch();
  const clientInteractions = useSelector(
    state => state.uiClientInteractions.meta.clientInteractions
  );
  const getOrganizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const communicationChainsByIds = useSelector(state => state.communicationChainsV1Resource.byIds);
  const selectedCheckBox = useSelector(state => state.uiClientInteractionPage.selectedCheckbox);
  const { t } = useTranslation();
  const crmEntitiesByIds = useSelector(state => state.crmEntitiesResource.byIds);

  if (
    Array.isArray(record.reviews) &&
    record.reviews.length > 0 &&
    Array.isArray(record.reviewFormulaResult)
  ) {
    const updatedReviews = [...record.reviews];
    record.reviews.forEach((review, index) => {
      if (record.reviewFormulaResult[index] !== undefined) {
        const formulaResult = record.reviewFormulaResult[index];

        updatedReviews[index] = {
          ...review,
          reviewFormulaResult: [formulaResult]
        };
      } else {
        updatedReviews[index] = {
          ...review,

          reviewFormulaResult: []
        };
      }
    });
  }

  const getFormulaResult = (obj, name) => {
    if (Array.isArray(obj.reviewFormulaResult)) {
      const foundItem = obj.reviewFormulaResult.find(item => item.name.includes(name));
      return foundItem || null;
    }
    return null;
  };

  const handleCheckboxChange = (communicationId, operatorId) => {
    selection.toggle(communicationId);
    startId = null;
    startId = communicationId;
    const isSelected = selection.isSelected(communicationId);
    const hasOperatorId = selectedCheckBox.some(item => item.operator_id === operatorId);
    if (isSelected) {
      // select checkbox
      if (!hasOperatorId) {
        const newCheckboxItem = {
          organization_id: getOrganizationId,
          operator_id: operatorId,
          communications: [communicationId]
        };

        dispatch(setCheckbox([...selectedCheckBox, newCheckboxItem]));
      } else {
        const updatedCheckBox = selectedCheckBox.map(item => {
          if (item.operator_id === operatorId) {
            const communications = [...item.communications, communicationId];
            return {
              ...item,
              communications
            };
          }
          return item;
        });
        dispatch(setCheckbox(updatedCheckBox));
      }
    } else {
      // unSelect checkbox
      const deleteCheckBox = selectedCheckBox.map(item => {
        if (item.operator_id === operatorId) {
          const communications = [...item.communications].filter(id => id !== communicationId);
          return {
            ...item,
            communications
          };
        }
        return item;
      });
      dispatch(setCheckbox(deleteCheckBox));
    }
  };

  const handleShiftCheckboxChange = communicationId => {
    const collectedIds = [];
    selection.toggle(communicationId);
    if (selectedCheckBox.length >= 1) {
      endId = communicationId;
    }
    let isCollectingIds = false;
    const reverseSelect = ids.indexOf(startId) > ids.indexOf(endId);
    // Выбор чекбоксов в обратную сторону и наоборот
    if (reverseSelect) {
      for (let i = ids.length - 1; i >= 0; i--) {
        const currentId = ids[i];
        if (currentId === startId) {
          isCollectingIds = true;
        }
        if (isCollectingIds) {
          collectedIds.push(currentId);
          if (!selection.isSelected(currentId)) {
            selection.toggle(currentId);
          }
          if (!selection.isSelected(startId) && !selection.isSelected(endId)) {
            selection.toggle(startId);
            selection.toggle(endId);
          }
        }
        if (currentId === endId) {
          break;
        }
      }
    } else {
      for (let i = 0; i < ids.length; i++) {
        const currentId = ids[i];
        if (currentId === startId) {
          isCollectingIds = true;
        }
        if (isCollectingIds) {
          collectedIds.push(currentId);
          if (!selection.isSelected(currentId)) {
            selection.toggle(currentId);
          }
          if (!selection.isSelected(startId) && !selection.isSelected(endId)) {
            selection.toggle(startId);
            selection.toggle(endId);
          }
        }
        if (currentId === endId) {
          break;
        }
      }
    }
    // Изначальный массив с выбранными чекбоксами
    const clinetInteractionsSelected = clientInteractions.filter(item =>
      collectedIds.includes(item.id)
    );
    // Фильтруем и создаём нужного вида объекты
    const communicationItem = clinetInteractionsSelected.map(item => {
      return {
        organization_id: getOrganizationId,
        operator_id: item.operatorId,
        communications: [item.id]
      };
    });
    // Объединяем communications
    const updatedCheckBox = communicationItem.reduce((acc, obj) => {
      const existingObj = acc.find(o => o.operator_id === obj.operator_id);
      if (existingObj) {
        existingObj.communications.push(...obj.communications);
      } else {
        acc.push(obj);
      }
      return acc;
    }, []);
    if (selectedCheckBox.length === 0) {
      return dispatch(setCheckbox(updatedCheckBox));
    }
    updatedCheckBox.push(...selectedCheckBox);
    // Избавляемся от дублей
    const filteredCheckbox = updatedCheckBox.reduce((acc, obj) => {
      const existingObj = acc.find(o => o.operator_id === obj.operator_id);
      if (existingObj) {
        existingObj.communications = [
          ...new Set([...existingObj.communications, ...obj.communications])
        ];
      } else {
        acc.push(obj);
      }
      return acc;
    }, []);
    dispatch(setCheckbox(filteredCheckbox));
  };

  const onCopy = () => {
    message.success(t('components.recordPlayer.copyLinkSuccess'));
  };

  const handleGetCommunicationChainNumber = communicationChainsIds => {
    if (isEmpty(communicationChainsIds)) return;

    const communicationChains = Object.values(communicationChainsByIds).filter(
      chain => communicationChainsIds && communicationChainsIds.includes(chain.id)
    );

    if (isEmpty(communicationChains)) {
      return <LoadingOutlined style={{ color: '#fff' }} />;
    }

    return (
      <div>
        {communicationChains &&
          communicationChains.map(chain => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Text key={chain.id}>
                <a href={`/communication-chains/${chain.id}`} target="_blank" rel="noreferrer">
                  {getChainNumber(chain?.clientPhoneNumber, '#8492e8', 'left')}
                  {' '}
                </a>
              </Text>
              <CopyToClipboard
                text={`${window.location.origin}/communication-chains/${chain.id}`}
                onCopy={onCopy}
                style={{ marginLeft: '8px', color: '#8492e8' }}
              >
                <LinkOutlined style={{ width: '20px', color: '#8492e8' }} />
              </CopyToClipboard>
            </div>
          ))}
      </div>
    );
  };

  const getReviewsCount = record => {
    if (!!record?.reviews && Array.isArray(record.reviews)) {
      return record.reviews.length;
    }

    return null;
  };

  const renderColumnData = useMemo(
    () => ({
      chainCallScore: (data, record) => {
        const scoreResult = record?.scoreResult;
        const phoneCallScore = scoreResult?.phoneCall;
        return (
          phoneCallScore && (
            <CenteredData style={{ cursor: 'pointer' }}>
              {`${beatifyFloat(phoneCallScore)}`}
            </CenteredData>
          )
        );
      },
      checkboxColumn: (data, record) => {
        return (
          <Checkbox
            style={{
              position: 'absolute',
              top: '48%',
              left: '40%',
              transform: 'translate(-50%, -50%)'
            }}
            disabled={typeof record.id === 'undefined'}
            checked={selection.isSelected(record.id)}
            onClick={event => {
              if (event.shiftKey) {
                handleShiftCheckboxChange(record.id);
              } else {
                handleCheckboxChange(record.id, record.operator.id);
              }
            }}
          />
        );
      },
      communicationType: (
        type,
        { reviewId, operator, id, communicationType, clientInteractionType }
      ) =>
        operator?.active ? (
          <ClientInteractionLink
            communication={{ communicationType, clientInteractionType }}
            isReview={!!reviewId}
            id={reviewId || id}
            style={{ width: '24px', height: '24px', display: 'block' }}
          />
        ) : (
          <DeactivatedUserLinkToCommunication>
            <ClientInteractionLink
              style={{
                width: '24px',
                height: '24px',
                display: 'block',
                color: 'var(--blue_2)'
              }}
              communication={{ communicationType, clientInteractionType }}
              isReview={!!reviewId}
              id={reviewId || id}
            />
          </DeactivatedUserLinkToCommunication>
        ),
      // Дата и время
      startedAt: data =>
        data && (
          <CenteredData>
            <Text strong style={{ paddingRight: '4px' }}>
              {`${moment(data).format('DD/MM/YYYY, HH:mm')}`}
            </Text>
          </CenteredData>
        ),
      link: (data, record) => {
        let nav;
        if (record?.reviewId) {
          nav = '/reviews/';
        } else if (record.clientInteractionType === 'communication_chain') {
          nav = '/communication-chains/';
        } else if (record.clientInteractionType === 'phone_call') {
          nav = '/calls/';
        } else {
          nav = '/text-communications/';
        }

        const reviewId = record?.reviewId || record.id;
        const targetUrl = nav + reviewId;

        return (
          <CenteredData>
            <a href={targetUrl} target="_blank" rel="noreferrer">
              {t('general.open')}
            </a>
          </CenteredData>
        );
      },
      communicationLink: (data, record) => {
        let nav;
        const id = record?.reviewId ? record.reviewClientInteractionId : record.id;
        if (record.clientInteractionType === 'communication_chain') {
          nav = '/communication-chains/';
        } else if (record.clientInteractionType === 'phone_call') {
          nav = '/calls/';
        } else {
          nav = '/text-communications/';
        }

        const targetUrl = nav + id;

        return (
          <CenteredData>
            <a href={targetUrl} target="_blank" rel="noreferrer">
              {t('general.open')}
            </a>
          </CenteredData>
        );
      },
      chainCallCS: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.phoneCall;
        if (results && results['консультационные навыки']) {
          output = beatifyFloat(results['консультационные навыки']);
        }

        if (results && !output && results['оценка "консультационные навыки"']) {
          output = beatifyFloat(results['оценка "консультационные навыки"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainCallSS: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.phoneCall;
        if (results && results['навыки продаж']) {
          output = beatifyFloat(results['навыки продаж']);
        }

        if (results && !output && results['оценка "навыки продаж"']) {
          output = beatifyFloat(results['оценка "навыки продаж"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainCallFR: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.phoneCall;
        if (results && results['итоговая оценка']) {
          output = beatifyFloat(results['итоговая оценка']);
        }

        if (results && !output && results['оценка "итоговая оценка"']) {
          output = beatifyFloat(results['оценка "итоговая оценка"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainChatCS: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.chat;
        if (results && results['консультационные навыки']) {
          output = beatifyFloat(results['консультационные навыки']);
        }

        if (results && !output && results['оценка "консультационные навыки"']) {
          output = beatifyFloat(results['оценка "консультационные навыки"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainChatSS: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.chat;
        if (results && results['навыки продаж']) {
          output = beatifyFloat(results['навыки продаж']);
        }

        if (results && !output && results['оценка "навыки продаж"']) {
          output = beatifyFloat(results['оценка "навыки продаж"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainChatFR: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.chat;
        if (results && results['итоговая оценка']) {
          output = beatifyFloat(results['итоговая оценка']);
        }

        if (results && !output && results['оценка "итоговая оценка"']) {
          output = beatifyFloat(results['оценка "итоговая оценка"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainOtherCS: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.other;
        if (results && results['консультационные навыки']) {
          output = beatifyFloat(results['консультационные навыки']);
        }

        if (results && !output && results['оценка "консультационные навыки"']) {
          output = beatifyFloat(results['оценка "консультационные навыки"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainOtherSS: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.other;
        if (results && results['навыки продаж']) {
          output = beatifyFloat(results['навыки продаж']);
        }

        if (results && !output && results['оценка "навыки продаж"']) {
          output = beatifyFloat(results['оценка "навыки продаж"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainOtherFR: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.other;
        if (results && results['итоговая оценка']) {
          output = beatifyFloat(results['итоговая оценка']);
        }

        if (results && !output && results['оценка "итоговая оценка"']) {
          output = beatifyFloat(results['оценка "итоговая оценка"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainTicketCS: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.ticket;
        if (results && results['консультационные навыки']) {
          output = beatifyFloat(results['консультационные навыки']);
        }

        if (results && !output && results['оценка "консультационные навыки"']) {
          output = beatifyFloat(results['оценка "консультационные навыки"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainTicketSS: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.ticket;
        if (results && results['навыки продаж']) {
          output = beatifyFloat(results['навыки продаж']);
        }

        if (results && !output && results['оценка "навыки продаж"']) {
          output = beatifyFloat(results['оценка "навыки продаж"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainTicketFR: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.ticket;
        if (results && results['итоговая оценка']) {
          output = beatifyFloat(results['итоговая оценка']);
        }

        if (results && !output && results['оценка "итоговая оценка"']) {
          output = beatifyFloat(results['оценка "итоговая оценка"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainChainCS: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.communicationChain;
        if (results && results['консультационные навыки']) {
          output = beatifyFloat(results['консультационные навыки']);
        }

        if (results && !output && results['оценка "консультационные навыки"']) {
          output = beatifyFloat(results['оценка "консультационные навыки"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainChainSS: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.communicationChain;
        if (results && results['навыки продаж']) {
          output = beatifyFloat(results['навыки продаж']);
        }

        if (results && !output && results['оценка "навыки продаж"']) {
          output = beatifyFloat(results['оценка "навыки продаж"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainChainFR: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.communicationChain;
        if (results && results['итоговая оценка']) {
          output = beatifyFloat(results['итоговая оценка']);
        }

        if (results && !output && results['оценка "итоговая оценка"']) {
          output = beatifyFloat(results['оценка "итоговая оценка"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainMailCS: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.email;
        if (results && results['консультационные навыки']) {
          output = beatifyFloat(results['консультационные навыки']);
        }

        if (results && !output && results['оценка "консультационные навыки"']) {
          output = beatifyFloat(results['оценка "консультационные навыки"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainMailSS: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.email;
        if (results && results['навыки продаж']) {
          output = beatifyFloat(results['навыки продаж']);
        }

        if (results && !output && results['оценка "навыки продаж"']) {
          output = beatifyFloat(results['оценка "навыки продаж"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      chainMailFR: (data, record) => {
        let output = null;
        const results = record?.formulaResult?.formulasResult?.email;
        if (results && results['итоговая оценка']) {
          output = beatifyFloat(results['итоговая оценка']);
        }

        if (results && !output && results['оценка "итоговая оценка"']) {
          output = beatifyFloat(results['оценка "итоговая оценка"']);
        }

        return <CenteredData>{output}</CenteredData>;
      },
      // Дата создания
      createdAt: (data, record) => {
        let createdAt;
        if (Object.keys(record).includes('type') && record.type === 'client-interactions') {
          createdAt = record.createdAt;
        } else {
          createdAt = record.reviewCreatedAt;
        }
        return (
          <CenteredData>
            <Text strong style={{ paddingRight: '4px' }}>
              {`${moment(createdAt).format('DD/MM/YYYY, HH:mm')}`}
            </Text>
          </CenteredData>
        );
      },
      // Дата обновления
      updatedAt: (data, record) => {
        const getUpdatedAt = () => {
          // Если коммуникация оценена
          if (record.reviewUpdatedAt) {
            return record.reviewUpdatedAt;
          }
          // Если коммуникация без оценки
          return record?.updatedAt;
        };
        const updatedAt = getUpdatedAt();
        return (
          <CenteredData>
            <Text strong style={{ paddingRight: '4px' }}>
              {`${moment(updatedAt).format('DD/MM/YYYY, HH:mm')}`}
            </Text>
          </CenteredData>
        );
      },
      mediaUrl: (data, record) => {
        const mediaUrl = record?.mediaUrl;
        return (
          mediaUrl && (
            <CenteredData>
              <a href={mediaUrl} target="_blank" rel="noreferrer">
                {t('general.open')}
              </a>
            </CenteredData>
          )
        );
      },
      source: (data, record) => {
        const source = record?.source;
        return (
          source && (
            <CenteredData>
              <Text>{source}</Text>
            </CenteredData>
          )
        );
      },
      unitName: (data, record) => {
        const unitName = record?.unit?.name;
        return (
          unitName && (
            <CenteredData>
              <Text>{unitName}</Text>
            </CenteredData>
          )
        );
      },
      reviewsAmount: (data, record) => {
        return (
          <CenteredData>
            <Text>{getReviewsCount(record)}</Text>
          </CenteredData>
        );
      },
      duration: data =>
        data ? (
          <CenteredData>
            <Text strong>{utils.formatTime(data)}</Text>
          </CenteredData>
        ) : (
          ''
        ),
      avitoCGabove: (data, record) => {
        let res = getFormulaResult(record, 'онсультационные навыки');
        res = res ? <CenteredData>{beatifyFloat(res?.score)}</CenteredData> : null;
        return res;
      },
      avitoCGbelow: (data, record) => {
        let res = getFormulaResult(record, 'онсультационные навыки');
        res = res ? <CenteredData>{beatifyFloat(res?.maxScore)}</CenteredData> : null;
        return res;
      },
      avitoSGabove: (data, record) => {
        let res = getFormulaResult(record, 'авыки продаж');
        res = res ? <CenteredData>{beatifyFloat(res?.score)}</CenteredData> : null;
        return res;
      },
      avitoSGbelow: (data, record) => {
        let res = getFormulaResult(record, 'авыки продаж');
        res = res ? <CenteredData>{beatifyFloat(res?.maxScore)}</CenteredData> : null;
        return res;
      },
      avitoFGabove: (data, record) => {
        let res = getFormulaResult(record, 'тоговая оценка');
        res = res ? <CenteredData>{beatifyFloat(res?.score)}</CenteredData> : null;
        return res;
      },
      avitoFGbelow: (data, record) => {
        let res = getFormulaResult(record, 'тоговая оценка');
        res = res ? <CenteredData>{beatifyFloat(res?.maxScore)}</CenteredData> : null;
        return res;
      },
      clientInteractionsInChainCount: data =>
        data ? (
          <CenteredData>
            <Text strong>{data}</Text>
          </CenteredData>
        ) : (
          ''
        ),
      // Цепочка
      communicationChainId: (data, record) => {
        let urls;
        try {
          urls = JSON.parse(data);
        } catch (err) {
          urls = data;
        }

        if (urls && isArray(urls)) {
          return (
            <StyledTooltip
              style={{ cursor: 'pointer', color: '#fff' }}
              title={handleGetCommunicationChainNumber(urls)}
              placement="left"
            >
              <SText style={{ cursor: 'pointer' }}>
                Цепочки
                {' '}
                <FolderOpenOutlined style={{ width: '20px', marginRight: '40px' }} />
              </SText>
            </StyledTooltip>
          );
        }
      },
      direction: data => t(CALL_DIRECTION_LITERALS[data]),
      operatorId: (data, record) => {
        const userId = data || record?.reviewOperatorId;
        return userId ? <UserPreview userId={userId} disabled showAvatar margin="0px" /> : '';
      },
      isReviewed: (data, record) =>
        record.clientInteractionType &&
        (record.reviewId
          ? t('constants.reviewStatuses.reviewed')
          : t('constants.reviewStatuses.notReviewed')),
      reviewsCount: (data, record) => {
        const reviews = record?.reviews;

        if (reviews?.length === 1) {
          return (
            <CenteredLink to={`/reviews/${reviews[0].reviewId}`}>{reviews.length}</CenteredLink>
          );
        }

        return reviews?.length > 1 ? (
          <CenteredButton type="link" onClick={() => onRowExpand(record)}>
            {reviews.length}
          </CenteredButton>
        ) : null;
      },
      clientId: (data, record) => record?.client?.integrationUid || null,
      unitId: (data, record) => record?.unit?.name || null,
      nps: data => <CenteredData>{data}</CenteredData>,
      email: (data, record) => record?.email,
      clientPhoneNumber: data => data,
      partsCount: (data, record) => (
        <CenteredData>
          {record.textCommunicationPartsCount || record.textCommunicationPartsIds?.length || ''}
        </CenteredData>
      ),
      // Оценка
      score: (data, record) => {
        let score;
        if (record.reviewStatus === 'in_progress') {
          score = 'Результат проверки рассчитывается';
        } else {
          score =
            !Number.isNaN(record?.reviewChecklist?.score) &&
            (beatifyFloat(record?.reviewChecklist?.score) ?? data);
        }
        return <CenteredData>{score}</CenteredData>;
      },
      // formula: (data, record) => (
      //   let res = getFormulaResult(record, 'авыки продаж');
      //   res = res ? <CenteredData>{beatifyFloat(res?.result)}</CenteredData> : null;
      //   return res;
      // ),
      textSs: (data, record) => {
        let res = getFormulaResult(record, 'авыки продаж');
        res = res?.text ? <CenteredData>{res.text}</CenteredData> : null;
        return res;
      },
      textCs: (data, record) => {
        let res = getFormulaResult(record, 'онсультационные навыки');
        res = res?.text ? <CenteredData>{res.text}</CenteredData> : null;
        return res;
      },
      communicationId: (data, record) => {
        return <CenteredData>{record?.id}</CenteredData>;
      },
      textFr: (data, record) => {
        let res = getFormulaResult(record, 'тоговая оценка');
        res = res?.text ? <CenteredData>{res.text}</CenteredData> : null;
        return res;
      },
      ss: (data, record) => {
        let res = getFormulaResult(record, 'авыки продаж');
        res = res ? <CenteredData>{beatifyFloat(res?.result)}</CenteredData> : null;
        return res;
      },
      cs: (data, record) => {
        let res = getFormulaResult(record, 'онсультационные навыки');
        res = res ? <CenteredData>{beatifyFloat(res?.result)}</CenteredData> : null;
        return res;
      },
      fr: (data, record) => {
        let res = getFormulaResult(record, 'тоговая оценка');
        res = res ? <CenteredData>{beatifyFloat(res?.result)}</CenteredData> : null;
        return res;
      },
      // Результат по формуле
      result: (data, record) => {
        Array.isArray(record.reviewFormulaResult) &&
          record.reviewFormulaResult.map(review => {
            let reviewStatus;
            if (record.reviewStatus === 'in_progress') {
              reviewStatus = <LoadingOutlined style={{ color: '#0061D0' }} />;
            } else {
              reviewStatus = !Number.isNaN(review?.result) && beatifyFloat(review?.result);
            }
            return <CenteredData>{reviewStatus}</CenteredData>;
          });
      },
      chatId: (data, record) => record.communicationId || null,
      ticketId: (data, record) => record.communicationId || null,
      reviewReviewerId: data =>
        data ? <UserPreview disabled userId={data} showAvatar margin="0px" hidden /> : null,
      // Дата оценки
      reviewCreatedAt: data => {
        return (
          data && (
            <CenteredData>
              <Text strong style={{ paddingRight: '4px' }}>
                {`${moment(data).format('DD/MM/YYYY, HH:mm')}`}
              </Text>
            </CenteredData>
          )
        );
      },
      checklistDefinitionName: (data, record) => record?.reviewChecklistDefinition?.name || null,
      reviewComments: (data, record) => (
        <CommentsPreview
          comments={data || []}
          checklistData={{ checklist: record.reviewChecklist }}
        />
      ),
      reviewTasksCount: (data, record) => {
        const tasksCount = record?.tasks?.length || 0;

        return tasksCount > 1 ? (
          <CenteredButton type="link" onClick={() => onRowExpand(record)}>
            {tasksCount}
          </CenteredButton>
        ) : null;
      },
      taskTaskDefinitions: (data, record) => (
        <TagsDisplay tasks={record.tasks || []} truncate={16} />
      ),
      status: (data, record) =>
        (record?.type === 'client-interactions' || record?.type === 'client_interactions') && (
          <StatusSelector canAddStatus={false} allowLoadStatuses={false} call={record} />
        ),
      crmEntityId: (data, record) => {
        const crmEntity = get(crmEntitiesByIds, record.crmEntityId, {});
        const linkText = compact([crmEntity?.integrationUid, crmEntity?.title]).join(' ');
        return (
          <Link target="_blank" type="link" to={`crm-entity/${crmEntity?.id}`}>
            {`${linkText ? '#' : ''}${linkText}`}
          </Link>
        );
      },
      ...reduce(
        customFieldsByKeys,
        (acc, curr, key) => ({ ...acc, [key]: renderCustomField({ key, customFieldsByKeys }) }),
        {}
      )
    }),
    [onRowExpand, customFieldsByKeys, selection, communicationChainsByIds]
  );

  return renderColumnData[col] && renderColumnData[col](text, record)
    ? renderColumnData[col](text, record)
    : null;
};

export default ColumnsRender;

const CenteredButton = styled(Button)`
  width: 100%;
  align-self: center;
`;

const CenteredLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredData = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
`;
